import api from './Api';

export default {
  login(username, password) {
    return api.post('/api/user/login', {
      username,
      password,
    });
  },
  register(username, password) {
    return api.post('/api/user/signup', {
      username,
      password,
    });
  },
  getAllUsers() {
    return api.get('/api/user');
  },
  editUser(nickname, id) {
    return api.put(`/api/user/${id}`, {
      nickname,
    });
  },
  getUserConfig(userId) {
    return api.get(`/api/user/${userId}/config`);
  },
  createUserConfig(themeColor, isDarkMode, userId) {
    return api.post('/api/user/config', {
      themeColor,
      isDarkMode,
      userId,
    });
  },
  editUserConfig(themeColor, isDarkMode, id) {
    return api.put(`/api/user/config/${id}`, {
      themeColor,
      isDarkMode,
    });
  },
};
