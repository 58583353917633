<template>
  <div>
    <loader v-if="loading" :loading="loading" />
    <div v-else>
      <heading
        :title="isNewNote ? 'Nouvelle note' : 'Édition de note'"
      >
        <div class="ml-auto">
          <b-button
            v-if="!loading"
            class="btn-action"
            variant="primary"
            :pill="$store.state.isMobileSize"
            @click.stop="onSubmit"
          >
            <template v-if="!$store.state.isMobileSize">
              {{ isNewNote ? 'Créer' : 'Sauvegarder' }}
            </template>
            <check-icon v-else :title="isNewNote ? 'Créer' : 'Sauvegarder'" />
          </b-button>
        </div>
      </heading>
      <b-form>
        <b-form-group>
          <b-form-input
            v-model="note.title"
            placeholder="Titre de la note"
            :class="{ 'is-invalid' : errors.has('title')}"
            name="title"
            data-vv-as="title"
          />
          <b-form-invalid-feedback>
            {{ errors.first('title') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-form-textarea
            placeholder="Contenu de la note"
            v-model="note.content"
            :class="{ 'is-invalid' : errors.has('content')}"
            name="content"
            data-vv-as="content"
            no-resize
            rows="15"
          />
          <b-form-invalid-feedback>
            {{ errors.first('content') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="users.length"
        >
          <vue-multiselect
            v-model="owners"
            :options="users"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="false"
            :showNoResults="false"
            selectLabel="Presse entrée pour sélectionner"
            placeholder="Partager..."
            label="nickname"
            track-by="nickname"
            :preselect-first="false"
            :hide-selected="true"
          />
        </b-form-group>
        <a
          href="#"
          v-if="!isNewNote"
          class="text-danger small"
          @click="deleteNote(note.id)"
        >
          Supprimer la note
        </a>
      </b-form>
    </div>
  </div>
</template>

<script>
import NoteService from '../../services/NoteService';
import UserService from '../../services/UserService';

export default {
  name: 'EditNotes',
  data: () => ({
    loading: true,
    note: {
      title: null,
      content: null,
      owners: [],
      id: null,
      isFavorite: false,
    },
    users: [],
    owners: [],
    isNewNote: true,
  }),
  computed: {
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.owners.push({
        id: this.$store.state.user.userId,
        nickname: this.$store.state.user.nickname,
      });
      if (this.isNewNote) {
        NoteService.createNote(
          this.note.title,
          this.note.content,
          this.owners,
          this.note.isFavorite,
        )
          .then(() => {
            this.$router.push({ name: 'Notes' });
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log('Cannot create note: ', err);
          });
      } else {
        NoteService.updateNote(
          this.note.title,
          this.note.content,
          this.owners,
          this.note.isFavorite,
          this.note.id,
        )
          .then(() => {
            this.$router.push({ name: 'Notes' });
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log('Cannot update note: ', err);
          });
      }
    },
    deleteNote(noteId) {
      // eslint-disable-next-line
      if (window.confirm('Voulez-vous vraiment supprimer cette liste?')) {
        this.loading = true;
        NoteService.deleteNote(
          noteId,
        )
          .then(() => {
            this.loading = false;
            this.$router.push({ name: 'Notes' });
          })
          .catch((err) => {
            this.loading = false;
            console.log('Cannot delete note: ', err);
          });
      }
    },
  },
  async mounted() {
    this.users = (await UserService.getAllUsers()).data
      .filter((user) => user.id !== this.$store.state.user.userId);
    if (typeof this.$route.params.noteId !== 'undefined') {
      this.isNewNote = false;
      this.note = (await NoteService.getOneNote(this.$route.params.noteId)).data;
      this.owners = this.note.owners.filter((user) => user.id !== this.$store.state.user.userId);
    } else {
      this.note.owners.push({
        id: this.$store.state.user.userId,
        nickname: this.$store.state.user.nickname,
      });
    }
    this.loading = false;
  },
};
</script>
