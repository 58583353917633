<template>
  <div class="login">
    <b-card :class="{loading: loading}">
      <img class="login-logo" src="/logo.png" alt="logo"/>
      <h5 class="login-title">Connectez-vous</h5>
      <b-form @submit="login">
        <b-form-input
          id="username"
          v-model="user.username"
          type="text"
          required
          placeholder="Nom d'utilisateur"
        />
        <b-form-input
          id="password"
          v-model="user.password"
          type="password"
          required
          placeholder="Mot de passe"
        />
        <span
          v-if="error !== null"
          class="text-danger small"
        >
          {{ error }}
        </span>
        <b-button
          class="btn-block"
          type="submit"
          variant="primary"
        >
          Connexion
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import UserService from '../services/UserService';

export default {
  name: 'Login',
  data: () => ({
    user: {
      username: null,
      password: null,
    },
    loading: false,
    error: null,
  }),
  computed: {
  },
  methods: {
    login(e) {
      e.preventDefault();
      this.loading = true;
      UserService.login(this.user.username, this.user.password)
        .then((res) => {
          this.loading = false;
          this.error = null;
          this.$store.dispatch('setUser', res.data);
          this.$router.push({ name: 'Notes' });
        })
        .catch(() => {
          this.loading = false;
          this.error = 'Utilisateur ou mot de passe incorrect';
        });
    },
  },
  mounted() {
    this.$store.dispatch('setUser', null);
    this.$store.dispatch('setThemeColor', null);
    this.$store.dispatch('setIsDarkMode', null);
  },
};
</script>
