<template>
  <div class="heading">
    <div>
      <nav v-if="$route.name !== 'Notes' && $route.name !== 'Lists'" aria-label="breadcrumb">
        <ol class="breadcrumb heading__breadcrumb">
          <li class="breadcrumb-item" v-if="$route.name !== 'Notes' && $route.name !== 'Lists'">

            <router-link
              :to="{name: $route.name.indexOf('Note') >= 0 ? 'Notes' : 'Lists' }"
            >
              {{ $route.name.indexOf('Note') >= 0 ? 'Notes' : 'Listes' }}
            </router-link>
          </li>
          <li class="breadcrumb-item" v-for="(item, index) in breadcrumb" :key="index">
            <router-link :to="item.to">{{ item.text }}</router-link>
          </li>
        </ol>
      </nav>
      <h1 class="heading__title">{{ title }}</h1>
    </div>

    <slot></slot>

  </div>
</template>

<script>
export default {
  props: {
    breadcrumb: {
      type: Array,
    },
    title: {
      type: String,
    },
  },
};
</script>
