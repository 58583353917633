<template>
  <div id="app">
    <div>
      <div
        v-if="$store.state.user"
        class="main-wrapper"
      >
        <sidebar />
        <div id="main-container" class="main-container">
          <router-view
            class="router-container"
            :key="$route.path"
          />
        </div>
      </div>
      <router-view v-else />
    </div>
  </div>
</template>

<script>
import config from './config/config';
import Sidebar from './components/layout/Sidebar.vue';
import UserService from './services/UserService';

export default {
  name: 'App',
  data() {
    return {
    };
  },
  components: {
    Sidebar,
  },
  computed: {
    isMobileSize: {
      get() {
        return this.$store.getters.getIsMobileSize;
      },
      set(value) {
        this.$store.dispatch('setIsMobileSize', value);
      },
    },
  },
  methods: {
    onResize() {
      if ((window.innerWidth <= config.maxMobileSize) !== this.isMobileSize) {
        this.isMobileSize = !this.isMobileSize;
      }
    },
  },
  async mounted() {
    this.$store.dispatch('setIsMobileSize', window.innerWidth < config.maxMobileSize);
    if (this.$store.state.user !== null) {
      this.$store.dispatch('setThemeColor', (await UserService.getUserConfig(this.$store.state.user.userId)).data.themeColor);
      this.$store.dispatch('setIsDarkMode', (await UserService.getUserConfig(this.$store.state.user.userId)).data.isDarkMode);
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
