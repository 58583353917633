<template>
  <div>
    <loader v-if="loading" :loading="loading" />
    <div v-else>
      <heading
        title="Notes"
      >
        <div class="ml-auto">
          <b-button
            v-if="!loading"
            class="btn-action"
            variant="primary"
            :pill="$store.state.isMobileSize"
            @click.stop="$router.push({ name: 'AddNote' })"
          >
            <template v-if="!$store.state.isMobileSize">Ajouter une note</template>
            <plus-icon v-else title="Ajouter une note" />
          </b-button>
        </div>
      </heading>
      <div class="cards">
        <b-card
          class="card-clickable"
          no-body
          @click.stop="$router.push({ name: 'EditNote', params: { noteId: note.id } })"
          v-for="(note, noteIndex) in orderedNotes"
          :key="noteIndex"
        >
          <b-card-header>
            <div class="card-header__title">
              {{ note.title }}
            </div>
            <div class="ml-auto">
              <star-icon
                @click.stop="setFavorite(note, noteIndex)"
                v-if="note.isFavorite"
              />
              <star-outline-icon
                @click.stop="setFavorite(note, noteIndex)"
                v-else
              />
              <delete-icon
                @click.stop="deleteNote(note.id, noteIndex)"
              />
            </div>
            <!--<div class="card-header__options">
              <b-dropdown
                no-caret
                right
                variant="link"
                size="sm"
                toggle-class="text-decoration-none"
              >
                <template v-slot:button-content>
                  <dots-vertical-icon title=""></dots-vertical-icon>
                </template>
                <b-dropdown-item
                  variant="danger"
                  @click.stop="deleteNote(note.id, noteIndex)"
                >
                  Supprimer
                </b-dropdown-item>
              </b-dropdown>
            </div>-->
          </b-card-header>
          <b-card-body>
            {{ note.content }}
          </b-card-body>
          <b-card-footer>
          <span>
            {{ format_date(note.updatedAt) }}
          </span>
            <div class="ml-auto">
              <b-badge
                variant="primary"
                v-for="(owner, ownerIndex) in note.owners"
                :key="ownerIndex"
                class="ml-2"
              >
                {{ owner.nickname }}
              </b-badge>
            </div>
          </b-card-footer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import NoteService from '../../services/NoteService';

export default {
  name: 'Notes',
  data: () => ({
    notes: [],
    loading: true,
  }),
  computed: {
    orderedNotes() {
      const favoritesNotes = [];
      const unfavoritesNotes = [];
      this.notes.forEach((note) => {
        if (note.isFavorite) {
          favoritesNotes.push(note);
        } else {
          unfavoritesNotes.push(note);
        }
      });
      return favoritesNotes.concat(unfavoritesNotes);
    },
  },
  methods: {
    setFavorite(note, noteIndex) {
      NoteService.updateNote(
        note.title,
        note.content,
        note.owners,
        !note.isFavorite,
        note.id,
      )
        .then(() => {
          this.orderedNotes[noteIndex].isFavorite = !note.isFavorite;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log('Cannot update note: ', err);
        });
    },
    deleteNote(noteId, noteIndex) {
      // eslint-disable-next-line
      if (window.confirm('Voulez-vous vraiment supprimer cette note?')) {
        this.loading = true;
        NoteService.deleteNote(
          noteId,
        )
          .then(() => {
            this.orderedNotes.splice(noteIndex, 1);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log('Cannot delete note: ', err);
          });
      }
    },
    format_date(value) {
      return moment(String(value)).format('DD/MM/YYYY - HH:mm');
    },
  },
  async mounted() {
    this.notes = (await NoteService.getOwnedNotes(this.$store.state.user.userId)).data;
    this.loading = false;
  },
};
</script>
