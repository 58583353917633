import Vue from 'vue';
import Vuex from 'vuex';
import PersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    setIsDarkMode(state, isDarkMode) {
      state.isDarkMode = isDarkMode;
    },
    setIsMobileSize(state, isMobileSize) {
      state.isMobileSize = isMobileSize;
    },
    setThemeColor(state, themeColor) {
      state.themeColor = themeColor;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    setIsDarkMode(context, isDarkMode) {
      context.commit('setIsDarkMode', isDarkMode);
    },
    setIsMobileSize(context, isMobileSize) {
      context.commit('setIsMobileSize', isMobileSize);
    },
    setThemeColor(context, themeColor) {
      context.commit('setThemeColor', themeColor);
    },
    setUser(context, user) {
      context.commit('setUser', user);
    },
  },
  getters: {
    getIsDarkMode: (state) => state.isDarkMode,
    getIsMobileSize: (state) => state.isMobileSize,
    getThemeColor: (state) => state.themeColor,
    getUser: (state) => state.user,
  },
  plugins: [PersistedState({
    reducer: (state) => ({
      isDarkMode: state.isDarkMode,
      isMobileSize: state.isMobileSize,
      themeColor: state.themeColor,
      user: state.user,
    }),
  })],
});
