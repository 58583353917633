<template>
  <div>
    <h2>Register</h2>
    <form v-on:submit="register">
      <input v-model="user.username" type="text" name="username" /><br>
      <input v-model="user.password" type="password" name="password" /><br>
      <input type="submit" value="Register" />
    </form>
  </div>
</template>

<script>
import UserService from '../services/UserService';

export default {
  name: 'Register',
  data: () => ({
    user: {
      username: null,
      password: null,
    },
  }),
  methods: {
    register(e) {
      e.preventDefault();
      UserService.register(this.user.username, this.user.password)
        .then((res) => {
          console.log(res);
          UserService.createUserConfig(
            this.$vuetify.theme.themes.light.primary,
            false,
            res.data.id,
          ).then(() => {
            this.$router.push({ name: 'Login' });
          }).catch((err) => {
            console.log('Cannot register: ', err);
          });
        })
        .catch((err) => {
          console.log('Cannot register: ', err);
        });
    },
  },
};
</script>
