<template>
  <div
    v-if="loading"
    class="loader-container"
  >
    <svg
      width="60px"
      height="200px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      class="lds-rolling"
      style="background: none;"
    >
      <circle
        class="loader"
        cx="50"
        cy="50"
        fill="none"
        stroke="blue"
        stroke-width="8"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
        transform="rotate(216 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        >
        </animateTransform>
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
  },
};
</script>
