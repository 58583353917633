<template>
  <div :class="{'sidebar-wrapper': true, 'sidebar-mobile': $store.state.isMobileSize}">
    <div v-if="!$store.state.isMobileSize" class="sidebar-header">
      <router-link :class="{active: $route.name === 'Notes'}" :to="{name: 'Notes'}">
        <img alt="logo" class="logo" src="/logo-white.png">
      </router-link>
    </div>
    <template v-if="$store.state.isMobileSize">
      <router-link :class="{active: $route.name === 'Notes'}" :to="{name: 'Notes'}">
        <note-icon />
      </router-link>
      <router-link :class="{active: $route.name === 'Lists'}" :to="{name: 'Lists'}">
        <format-list-bulleted-icon />
      </router-link>
      <router-link :class="{active: $route.name === 'Settings'}" :to="{name: 'Settings'}">
        <cog-icon />
      </router-link>
    </template>
    <template v-else>
      <div class="sidebar-content">
        <router-link :class="{active: $route.name === 'Notes'}" :to="{name: 'Notes'}">
          <note-icon />
        </router-link>
        <router-link :class="{active: $route.name === 'Lists'}" :to="{name: 'Lists'}">
          <format-list-bulleted-icon />
        </router-link>
      </div>
      <div class="sidebar-footer">
        <router-link :class="{active: $route.name === 'Settings'}" :to="{name: 'Settings'}">
          <cog-icon />
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
