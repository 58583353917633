import Vue from 'vue';
import * as VeeValidate from 'vee-validate';
import BootstrapVue from 'bootstrap-vue';
import VueMultiselect from 'vue-multiselect';

import Check from 'vue-material-design-icons/Check.vue';
import Cog from 'vue-material-design-icons/Cog.vue';
import Delete from 'vue-material-design-icons/Delete.vue';
import DotsVertical from 'vue-material-design-icons/DotsVertical.vue';
import Drag from 'vue-material-design-icons/Drag.vue';
import FormatListBulleted from 'vue-material-design-icons/FormatListBulleted.vue';
import Loading from 'vue-material-design-icons/Loading.vue';
import Note from 'vue-material-design-icons/Note.vue';
import Plus from 'vue-material-design-icons/Plus.vue';
import Star from 'vue-material-design-icons/Star.vue';
import StarOutline from 'vue-material-design-icons/StarOutline.vue';

import Heading from './components/layout/Heading.vue';
import Loader from './components/layout/Loader.vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './assets/scss/main.scss';

Vue.component('heading', Heading);
Vue.component('loader', Loader);

Vue.component('check-icon', Check);
Vue.component('cog-icon', Cog);
Vue.component('delete-icon', Delete);
Vue.component('dots-vertical-icon', DotsVertical);
Vue.component('drag-icon', Drag);
Vue.component('format-list-bulleted-icon', FormatListBulleted);
Vue.component('loading-icon', Loading);
Vue.component('note-icon', Note);
Vue.component('plus-icon', Plus);
Vue.component('star-icon', Star);
Vue.component('star-outline-icon', StarOutline);

Vue.component('vue-multiselect', VueMultiselect);

Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
