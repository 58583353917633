import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import store from '../store/index';
import config from '../config/config';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    isDark: typeof store.state.isDarkMode !== 'undefined'
      && store.state.isDarkMode !== null
      ? store.state.isDarkMode
      : false,
    themes: {
      light: {
        primary: typeof store.state.themeColor !== 'undefined'
          && store.state.themeColor !== null
          ? store.state.themeColor
          : config.DEFAULT_THEME_COLOR,
      },
      dark: {
        primary: typeof store.state.themeColor !== 'undefined'
          && store.state.themeColor !== null
          ? store.state.themeColor
          : config.DEFAULT_THEME_COLOR,
      },
    },
  },
});
