import Vue from 'vue';
import Router from 'vue-router';

import store from '../store/index';

import Lists from '../views/lists/Index.vue';
import EditList from '../views/lists/Edit.vue';
import Notes from '../views/notes/Index.vue';
import EditNote from '../views/notes/Edit.vue';
import Settings from '../views/Settings.vue';

import Login from '../components/Login.vue';
import Register from '../components/Register.vue';

Vue.use(Router);

const routes = [
  {
    path: '/notes',
    name: 'Notes',
    component: Notes,
  },
  {
    path: '/notes/new',
    name: 'AddNote',
    component: EditNote,
  },
  {
    path: '/notes/:noteId',
    name: 'EditNote',
    component: EditNote,
  },
  {
    path: '/lists',
    name: 'Lists',
    component: Lists,
  },
  {
    path: '/lists/new',
    name: 'AddList',
    component: EditList,
  },
  {
    path: '/lists/:listId',
    name: 'EditList',
    component: EditList,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '*',
    redirect: '/notes',
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Register') {
    next();
  } else if (!store.state.user && to.name !== ('Login')) {
    next({
      name: 'Login',
    });
  } else {
    next();
  }
});

export default router;
