import api from './Api';

export default {
  async createList(title, listItems, owners, isFavorite) {
    return api.post('api/list', {
      title,
      listItems,
      owners,
      isFavorite,
    });
  },

  async getAllLists() {
    return api.get('api/list');
  },

  async getOneList(listId) {
    return api.get(`api/list/${listId}`);
  },

  async getOwnedLists(userId) {
    return api.get(`api/list/mine/${userId}`);
  },

  async deleteList(id) {
    return api.delete(`api/list/${id}`);
  },

  async updateList(title, listItems, owners, isFavorite, id) {
    return api.put(`api/list/${id}`, {
      title,
      listItems,
      owners,
      isFavorite,
    });
  },
};
