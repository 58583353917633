<template>
  <div>
    <loader v-if="loading" :loading="loading" />
    <div v-else>
      <heading
        :title="isNewList ? 'Nouvelle liste' : 'Édition de liste'"
      >
        <div class="ml-auto">
          <b-button
            v-if="!loading && !isCreatingItem"
            class="btn-action"
            variant="primary"
            :pill="$store.state.isMobileSize"
            @click.stop="onSubmit"
          >
            <template v-if="!$store.state.isMobileSize">
              {{ isNewList ? 'Créer' : 'Sauvegarder' }}
            </template>
            <check-icon v-else :title="isNewList ? 'Créer' : 'Sauvegarder'" />
          </b-button>
        </div>
      </heading>
      <b-form>
        <b-form-group>
          <b-form-input
            v-model="list.title"
            placeholder="Titre de la liste"
            :class="{ 'is-invalid' : errors.has('title')}"
            name="title"
            data-vv-as="title"
          />
          <b-form-invalid-feedback>
            {{ errors.first('title') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="users.length"
        >
          <vue-multiselect
            v-model="owners"
            :options="users"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Partager..."
            :showNoResults="false"
            selectLabel="Presse entrée pour sélectionner"
            label="nickname"
            track-by="nickname"
            :preselect-first="false"
            :hide-selected="true"
          />
        </b-form-group>

        <draggable
          handle=".drag-icon"
          v-model="list.listItems"
        >
          <b-card
            class="mb-3"
            no-body
            v-for="(listCategory, listCategoryIndex) in list.listItems"
            :key="listCategoryIndex"
          >
            <b-card-body
              v-if="listCategory.length"
              class="p-0 pt-1"
            >
              <div class="d-flex justify-content-center">
                <drag-icon />
              </div>
              <draggable
                v-model="list.listItems[listCategoryIndex]"
                handle=".drag-icon"
              >
                <div
                  class="d-flex w-100 align-items-center bg-white px-4"
                  v-for="(item, itemIndex) in list.listItems[listCategoryIndex]"
                  :key="itemIndex"
                >
                  <b-form-checkbox
                    v-model="item.isChecked"
                    @change="forceUpdate()"
                  />
                  <b-form-input
                    v-model="item.title"
                  />
                  <drag-icon />
                </div>
              </draggable>
            </b-card-body>
            <a
              v-else
              href="#"
              class="text-danger small position-absolute"
              style="right: 1rem; top: .5rem;"
              @click="list.listItems.splice(listCategoryIndex, 1)"
            >
              Supprimer
            </a>
            <b-form>
              <b-form-input
                class="pl-4"
                :ref="`newListItem-${listCategoryIndex}`"
                placeholder="Nouvel élément..."
                @input="onEditInputChange"
                @keypress.native.stop="addItem($event, listCategoryIndex)"
              />
            </b-form>
            <a
              href="#"
              v-if="isCheckedItems(listCategoryIndex)"
              class="text-danger small ml-auto mr-4 mb-3"
              @click="deleteCheckedItems(listCategoryIndex)"
            >
              Supprimer les éléments sélectionnés
            </a>
          </b-card>
        </draggable>
        <b-button
          class="mb-3"
          size="sm"
          variant="primary"
          @click="createCategory"
        >
          Nouvelle catégorie
        </b-button>
        <div>
          <a
            href="#"
            v-if="!isNewList"
            class="text-danger small"
            @click="deleteList(list.id)"
          >
            Supprimer la liste
          </a>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import ListService from '../../services/ListService';
import UserService from '../../services/UserService';

export default {
  name: 'EditLists',
  data: () => ({
    loading: true,
    list: {
      title: null,
      owners: [],
      id: null,
      listItems: [],
      isFavorite: false,
    },
    users: [],
    owners: [],
    isNewList: true,
    isCreatingItem: false,
  }),
  components: {
    Draggable,
  },
  computed: {
  },
  methods: {
    isCheckedItems(index) {
      return this.list.listItems !== null
      && this.list.listItems[index] !== null
        ? this.list.listItems[index].filter((listItem) => listItem.isChecked === true).length > 0
        : false;
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    onSubmit() {
      this.loading = true;
      this.owners.push({
        id: this.$store.state.user.userId,
        nickname: this.$store.state.user.nickname,
      });
      this.list.listItems.forEach((list, index) => {
        this.list.listItems[index].index = index;
        this.list.listItems[index] = list.map((item, itemIndex) => ({
          title: item.title,
          isChecked: item.isChecked,
          index: itemIndex,
        }));
      });
      if (this.isNewList) {
        ListService.createList(
          this.list.title,
          this.list.listItems,
          this.owners,
          this.list.isFavorite,
        )
          .then(() => {
            this.loading = false;
            this.$router.push({ name: 'Lists' });
          })
          .catch((err) => {
            this.loading = false;
            console.log('Cannot create list: ', err);
          });
      } else {
        ListService.updateList(
          this.list.title,
          this.list.listItems,
          this.owners,
          this.list.isFavorite,
          this.list.id,
        )
          .then(() => {
            this.loading = false;
            this.$router.push({ name: 'Lists' });
          })
          .catch((err) => {
            this.owners.pop();
            this.loading = false;
            console.log('Cannot update list: ', err);
          });
      }
    },
    deleteList(listId) {
      // eslint-disable-next-line
      if (window.confirm('Voulez-vous vraiment supprimer cette liste?')) {
        this.loading = true;
        ListService.deleteList(
          listId,
        )
          .then(() => {
            this.loading = false;
            this.$router.push({ name: 'Lists' });
          })
          .catch((err) => {
            this.loading = false;
            console.log('Cannot delete list: ', err);
          });
      }
    },
    update() {
      this.owners.push({
        id: this.$store.state.user.userId,
        nickname: this.$store.state.user.nickname,
      });
      this.list.listItems.forEach((list, i) => {
        this.list.listItems[i].index = i;
        this.list.listItems[i] = list.map((item, itemIndex) => ({
          title: item.title,
          isChecked: item.isChecked,
          index: itemIndex,
        }));
      });
      if (this.isNewList) {
        ListService.createList(
          this.list.title,
          this.list.listItems,
          this.owners,
          this.list.isFavorite,
        )
          .then((e) => {
            this.owners.pop();
            this.loading = false;
            if (e.data.id) {
              this.$router.push({ name: 'EditList', params: { listId: e.data.id } });
            }
          })
          .catch((err) => {
            this.owners.pop();
            this.loading = false;
            console.log('Cannot create list: ', err);
          });
      } else {
        ListService.updateList(
          this.list.title,
          this.list.listItems,
          this.owners,
          this.list.isFavorite,
          this.list.id,
        )
          .then(() => {
            this.owners.pop();
            this.loading = false;
          })
          .catch((err) => {
            this.owners.pop();
            this.loading = false;
            console.log('Cannot update list: ', err);
          });
      }
    },
    deleteCheckedItems(index) {
      // eslint-disable-next-line
      if (window.confirm('Voulez-vous vraiment supprimer les éléments sélectionnés?')) {
        this.list.listItems[index] = this.list.listItems[index].filter(
          (listItem) => listItem.isChecked === false,
        );
        this.update();
        this.$forceUpdate();
      }
    },
    addItem($event, index) {
      if ($event.keyCode === 13) {
        $event.preventDefault();
        const title = this.$refs[`newListItem-${index}`][0].localValue;
        if (title !== null && title.length) {
          this.list.listItems[index].push({
            title,
            index: this.list.listItems[index].length,
            isChecked: false,
          });
        }
        this.isCreatingItem = false;
        this.$refs[`newListItem-${index}`][0].localValue = '';
        this.update();
      }
    },
    createCategory() {
      this.list.listItems.push([]);
    },
    onEditInputChange(e) {
      this.isCreatingItem = e !== '';
    },
  },
  async mounted() {
    this.users = (await UserService.getAllUsers()).data
      .filter((user) => user.id !== this.$store.state.user.userId);
    if (typeof this.$route.params.listId !== 'undefined') {
      this.isNewList = false;
      this.list = (await ListService.getOneList(this.$route.params.listId)).data;
      this.owners = this.list.owners.filter((user) => user.id !== this.$store.state.user.userId);
    }
    this.loading = false;
  },
};
</script>
