<template>
  <div>
    <loader v-if="loading" :loading="loading" />
    <div v-else>
      <heading
        title="Réglages"
      >
        <div class="ml-auto">
          <b-button
            v-if="!loading"
            class="btn-action"
            variant="primary"
            :pill="$store.state.isMobileSize"
            @click.stop="onSubmit"
          >
            <template v-if="!$store.state.isMobileSize">
              Saugevarder
            </template>
            <check-icon v-else title="Sauvegarder" />
          </b-button>
        </div>
      </heading>
      <b-form>
        <b-form-group
          label="Surnom"
        >
          <b-form-input
            v-model="user.nickname"
            placeholder="Surnom"
            v-validate="'required'"
            :class="{ 'is-invalid' : errors.has('nickname')}"
            name="nickname"
            data-vv-as="nickname"
          />
          <b-form-invalid-feedback>
            {{ errors.first('nickname') }}
          </b-form-invalid-feedback>
        </b-form-group>

<!--        <b-form-group>
          <b-form-checkbox
            v-model="userConfig.isDarkMode"
            name="isDarkMode"
            switch
          >
            Dark Mode
          </b-form-checkbox>
          <b-form-invalid-feedback>
            {{ errors.first('isDarkMode') }}
          </b-form-invalid-feedback>
        </b-form-group>-->
        <b-form-group
          label="Couleur du thème"
        >
          <chrome-color-picker
            v-model="color"
          />
        </b-form-group>
        <a
          href="#"
          @click="setDefaultThemeColor"
        >
          Thème par défaut
        </a>
      </b-form>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color';
import UserService from '../services/UserService';
import config from '../config/config';

export default {
  name: 'Settings',
  data: () => ({
    loading: true,
    user: {
      nickname: null,
    },
    userConfig: {
      color: '',
      isDarkMode: false,
    },
    color: null,
  }),
  watch: {
    color(value) {
      if ((value !== null) && (value !== this.$store.state.themeColor)) {
        this.userConfig.themeColor = value.hex;
        this.$vuetify.theme.themes.light.primary = value.hex;
      }
    },
  },
  components: {
    'chrome-color-picker': Chrome,
  },
  computed: {
    themeColor: {
      get() {
        return this.$store.getters.getThemeColor !== null ? this.$store.getters.getThemeColor : '';
      },
    },
  },
  methods: {
    onSubmit() {
      this.loading = true;
      if (this.userConfig.themeColor !== this.$store.getters.getThemeColor) {
        UserService.editUserConfig(
          this.userConfig.themeColor,
          false,
          this.userConfig.id,
        ).then(() => {
          this.$store.dispatch('setThemeColor', this.userConfig.themeColor);
          this.$vuetify.theme.themes.light.primary = this.userConfig.themeColor;
        }).catch(() => {
          this.loading = false;
        });
      }
      if (this.user.nickname !== this.$store.getters.getUser.nickname) {
        UserService.editUser(
          this.user.nickname,
          this.$store.getters.getUser.userId,
        ).then(() => {
          this.$store.dispatch('setUser', { ...this.$store.getters.getUser, nickname: this.user.nickname });
        }).catch(() => {
          this.loading = false;
        });
      }
      this.loading = false;
      this.$router.push({ name: 'Notes' });
    },
    setDefaultThemeColor() {
      this.userConfig.themeColor = config.DEFAULT_THEME_COLOR;
      this.$vuetify.theme.themes.light.primary = config.DEFAULT_THEME_COLOR;
    },
  },
  async mounted() {
    this.user.nickname = this.$store.state.user.nickname;
    this.userConfig = (await UserService.getUserConfig(this.$store.state.user.userId)).data;
    this.color = this.themeColor;
    this.loading = false;
  },
  beforeDestroy() {
    if (this.color.hex === this.$vuetify.theme.themes.light.primary) {
      this.$vuetify.theme.themes.light.primary = this.$store.getters.getThemeColor;
    }
  },
};
</script>
