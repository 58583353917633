import Axios from 'axios';

import config from '../config/config';
import router from '../router/index';

const api = Axios.create({
  baseURL: config.apiURL,
});

api.interceptors.request.use((conf) => {
  if (conf.url !== '/api/user/login' && conf.url !== '/api/user/signup' && conf.url !== '/api/user/config') {
    const vuexStorage = localStorage.getItem('vuex') ? JSON.parse(localStorage.getItem('vuex')) : null;
    const newConf = conf;
    if (!vuexStorage.user) {
      router.push({ name: 'Login' }).then(() => console.log('logged out'));
    }
    newConf.headers.Authorization = `Bearer ${vuexStorage.user.token}`;
    return newConf;
  }
  return conf;
},
(error) => Promise.reject(error));

export default api;
