import api from './Api';

export default {
  async createNote(title, content, owners, isFavorite) {
    return api.post('api/note', {
      title,
      content,
      owners,
      isFavorite,
    });
  },

  async getAllNotes() {
    return api.get('api/note');
  },

  async getOneNote(noteId) {
    return api.get(`api/note/${noteId}`);
  },

  async getOwnedNotes(userId) {
    return api.get(`api/note/mine/${userId}`);
  },

  async deleteNote(id) {
    return api.delete(`api/note/${id}`);
  },

  async updateNote(title, content, owners, isFavorite, id) {
    return api.put(`api/note/${id}`, {
      title,
      content,
      owners,
      isFavorite,
    });
  },
};
