<template>
  <div>
    <loader v-if="loading" :loading="loading" />
    <div v-else>
      <heading
        title="Listes"
      >
        <div class="ml-auto">
          <b-button
            v-if="!loading"
            class="btn-action"
            variant="primary"
            :pill="$store.state.isMobileSize"
            @click.stop="$router.push({ name: 'AddList' })"
          >
            <template v-if="!$store.state.isMobileSize">Ajouter une liste</template>
            <plus-icon v-else title="Ajouter une liste" />
          </b-button>
        </div>
      </heading>
      <div class="cards">
        <b-card
          class="card-clickable"
          no-body
          @click.stop="$router.push({ name: 'EditList', params: { listId: list.id } })"
          v-for="(list, listIndex) in orderedLists"
          :key="listIndex"
        >
          <b-card-header>
            <div class="card-header__title">
              {{ list.title }}
            </div>
            <div class="ml-auto">
              <star-icon
                @click.stop="setFavorite(list, listIndex)"
                v-if="list.isFavorite"
              />
              <star-outline-icon
                @click.stop="setFavorite(list, listIndex)"
                v-else
              />
              <delete-icon
                @click.stop="deleteList(list.id, listIndex)"
              />
            </div>
            <!--<div class="card-header__options">
              <b-dropdown
                no-caret
                right
                variant="link"
                size="sm"
                toggle-class="text-decoration-none"
              >
                <template v-slot:button-content>
                  <dots-vertical-icon title=""></dots-vertical-icon>
                </template>
                <b-dropdown-item
                  variant="danger"
                  @click.stop="deleteList(list.id, listIndex)"
                >
                  Supprimer
                </b-dropdown-item>
              </b-dropdown>
            </div>-->
          </b-card-header>
          <b-card-footer>
          <span>
            {{ format_date(list.updatedAt) }}
          </span>
            <div class="ml-auto">
              <b-badge
                variant="primary"
                v-for="(owner, ownerIndex) in list.owners"
                :key="ownerIndex"
                class="ml-2"
              >
                {{ owner.nickname }}
              </b-badge>
            </div>
          </b-card-footer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ListService from '../../services/ListService';

export default {
  name: 'Lists',
  data: () => ({
    lists: [],
    loading: true,
  }),
  computed: {
    orderedLists() {
      const favoritesLists = [];
      const unfavoritesLists = [];
      this.lists.forEach((list) => {
        if (list.isFavorite) {
          favoritesLists.push(list);
        } else {
          unfavoritesLists.push(list);
        }
      });
      return favoritesLists.concat(unfavoritesLists);
    },
  },
  methods: {
    setFavorite(list, listIndex) {
      ListService.updateList(
        list.title,
        list.listItems,
        list.owners,
        !list.isFavorite,
        list.id,
      )
        .then(() => {
          this.orderedLists[listIndex].isFavorite = !list.isFavorite;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log('Cannot update list: ', err);
        });
    },
    deleteList(listId, listIndex) {
      // eslint-disable-next-line
      if (window.confirm('Voulez-vous vraiment supprimer cette liste?')) {
        this.loading = true;
        ListService.deleteList(
          listId,
        )
          .then(() => {
            this.orderedLists.splice(listIndex, 1);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log('Cannot delete list: ', err);
          });
      }
    },
    format_date(value) {
      return moment(String(value)).format('DD/MM/YYYY - HH:mm');
    },
  },
  async mounted() {
    this.lists = (await ListService.getOwnedLists(this.$store.state.user.userId)).data;
    this.loading = false;
  },
};
</script>
